// Hightlights Styles
.highlights {
  margin-top: 2rem;
  @media (min-width: 600px) {
    margin-top: 0;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
  .highlightsColumn {
    min-width: 50%;
    &.column1 {
      order: 1;
    }
    &.column2 {
      order: 2;
    }
    &.column3 {
      order: 3;
    }
    &.column4 {
      order: 4;
    }
    &.column5 {
      order: 5;
    }
  }
  @include media-min($md) {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: baseline;
    .highlightsColumn {
      min-width: auto;
    }
  }
  .header {
    display:block;
    font-family: $fontMedium;
    font-size: 0.85rem;
    color: $darkBlue;
    max-width: auto;
    @include media-min($md) {
      max-width: 5.5rem;
    }
  }
  .value {
    display:block;
    font-family: $fontMedium;
    font-size: 1.85rem;
    color: $lightBlue;
  }
  .subtitlesWrapper {
    display:block;
    width: auto;
    @include media-min($md) {
      width: 25vw;
      @include media-min($xxl) { 
        max-width: 400px;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    .attributeContainer {
      .header {
        font-family: $fontMedium;
        font-size: .85rem;
        color: $ligthGrey1;
      }
    }
  }
}

// Summary Styles
h1 {
  grid-row: 2;
  color: #00aeef;
  font-weight: bold;
  margin: 0;
}

h4 {
  grid-row: 1;
  margin: 0;
}
