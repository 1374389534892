.portfolio-table {
  background-color: white;
  padding: 3rem 3rem 0 3rem;

  @media (min-width: 800px) {
    padding: 0 3rem;
  }

  .grid-table-row {
    .grid-table-cell {
      &:before {
        background-color: rgba(244, 244, 244, 1);
        border: 2px solid #ffffff;
      }
      &:first-child {
        display: none;
      }
    }

    .company {
      text-align: -webkit-center;
      p {
        color: $lightBlue;
        font-weight: bolder;
        margin: 0rem;
        @include media-min($xs) {
          width: 9rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include media-min($sm) {
          width: 15rem;
        }
        @include media-min($md) {
          width: inherit;
          white-space: normal;
        }
        &:last-child {
          display: none;
        }
      }
    }

    @media (min-width: 400px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: 5rem repeat(4, 2fr);

      .grid-table-cell {
        &:first-child {
          display: block;
          text-align: center;
          padding: 0px;

          img {
            height: 80%;
            width: 80%;
            max-height: 4rem;
            max-width: 4rem;
          }
        }

        &:nth-child(2) {
          text-align: start;
          padding: 0px;
          justify-self: left;
          grid-column-start: 2;
          grid-column-end: 3;
        }

        &:last-child {
          padding-right: 0;
          grid-column-start: 5;
          grid-column-end: 6;
          border-bottom: 0;
        }
      }

      .company {
        p {
          margin: 0.1rem;

          &:first-child {
            font-size: 1.2rem;
          }

          &:last-child {
            display: block;
            color: $mediumBlue;
            font-size: 0.75rem;
            // font-weight: bolder;
          }
        }
      }
    }
  }

  .grid-table-cell {
    &:last-of-type {
      font-size: 1rem;
      margin-top: 0.75rem;
      &:before {
        display: block;
      }
    }

    &:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 2;
      margin-top: 0;
    }

    @media (min-width: 800px) {
      &:last-of-type {
        // font-size: .8rem;
        margin-top: 0rem;
        &:before {
          display: none;
        }
      }
      margin-bottom: 0rem;
      &:not(:last-child) {
        border-right: 0px solid #ddd;
      }
      &:first-of-type {
        text-align: left;
        justify-self: start;
        flex-direction: row-reverse;
      }
      text-align: right;
      padding: 2rem 0.5rem 2rem 0;
      &:before {
        content: attr(data-title);
        display: none;
      }
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        border-bottom: 0;
        padding: 0px;
      }
      &:last-child {
        padding-right: 0;
        grid-column-start: 5;
        grid-column-end: 6;
        border-bottom: 0;
      }
    }
  }

  // Pagination Config
  .tablePagination {
    padding: 2rem 0;
  }
}
