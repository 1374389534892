// General
.companyPage {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    @media (min-width: 800px) {
      flex-direction: row;
    }
  
    .companyDescriptionContainer {
      width: 75%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding-bottom: 1rem;
      @media (min-width: 800px) {
        padding-bottom: 0;
      }
      .left {
        display: none;
        @media (min-width: 800px) {
          height: 10vw;
          max-height: 175px;
          width: 10vw;
          // max-width: 175px;
          background-color: #f8f8f8;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: auto;
            width: auto;
            max-width: 90%;
            max-height: 90%;
          }
        }
      }
      .right {
        padding: 5vw 0 0 4vw;
        @media (min-width: 800px) {
          padding: 1vw;
        }
  
        .companyName {
          color: #00aeef;
          margin: 0;
        }
  
        .companySector {
          margin: 0.25rem 0;
        }
  
        .companyLink {
          color: #00aeef;
        }
      }
    }
    
    .summaryContainer {
      width: auto;
      padding: 1.5rem 0 0 4vw;
      @include media-min($md) {
        padding: 1.5rem 0 0 11vw;
      }
      @include media-min($md) {
        width: 100%;
        padding: 1.5rem 0 0 1.5vw;
      }
      @include media-min($md) {
        width: 25%;
        padding: 1.5rem 0 0 1.5vw;
      }
      .highlights {
        margin-top: 0rem;
        .highlightsColumn {
          margin-bottom: 0rem;
          min-width: 100%;
          @include media-min(500px) {
            min-width: 50%;
            margin-bottom: 1rem;
          }
          @include media-min($md) {
            min-width: inherit;
          }
          &.column1, &.column4 {
            @include media-min($md) {
              .header:after {
                content:"\A  ";
                white-space: pre;
                min-width: 25%;
              }
            }
            @include media-min($md) { 
              min-width: inherit;
            }
          }
        }
        input {
          margin-top: .75rem;
          padding: 0.6rem 0.6rem !important;
          & + svg.MuiSelect-iconOutlined {
            right: 1rem!important;
          }
        }
      }
    }
  }
  
  .tabsWrapper {
    padding-bottom: 4vw;
    .MuiTabs-indicator {
      background-color: #00aeef!important;
    }
    .MuiTab-textColorPrimary.Mui-selected {
      color: #00aeef;
    }
    button:active,
    button:hover {
      background: rgba(0, 0, 0, 0.08);
      border: 0 solid rgba(0, 0, 0, 0.08);
    }
  }

  // Investment Attributes
  .attributesContainer {
    padding: 1.5rem 0 1.5rem 5vw;
    @include media-min($md) { 
      padding: 1.5rem 11vw;
    }
  
    .textButton {
      margin-top: 5vh;
      font-weight: bold;
      color: #1dacf5;
      cursor: pointer;
  
      span {
        font-size: 1.25rem;
      }
    }
  }
  
  .attributeGrid {
    display: inline-grid;
    align-content: center;
    grid-column-gap: 3vw;
    grid-row-gap: 1vw;
  
    grid-template-columns: repeat(1, 1fr);
  
    @include media-min(500px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 800px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // Text Button
  .textButton {
    margin: 1.5rem 0 1.5rem 5vw;
    @include media-min($md) { 
      margin: 1.5rem 11vw;
    }
    font-weight: bold;
    color: #1dacf5;
    cursor: pointer;
    width: fit-content;
    box-sizing: content-box;

    span {
      font-size: 1.25rem;
    }
  }

  .pageBodyBottomWrapper {
    .filtersMobileWrapper {
      margin-bottom: 2rem;
    }
  }
}