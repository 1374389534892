input {
  padding: .25rem .5rem;
  font-size: 1rem;
  color: $mediumBlue;
  width: 95%;
  display: block;
  border-radius: 0.5vw;
  border: 0px solid $ligthGrey3;
  background-color: $ligthGreyBg;
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

input.error {
  border-color: $error;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.input-feedback {
  color: $error;
  font-size: 0.85rem;
  margin-top: 0.35rem;
  &.countCharactersMargin{
    width: calc(100% - 70px);
    display: block;
    float: left;
  }
}

.textarea {
  padding: .25rem .5rem;
  font-size: 1rem;
  color: $mediumBlue;
  width: 95%;
  display: block;
  border-radius: 0.5vw!important;
  background-color: $ligthGreyBg;
  box-sizing: border-box;
  .MuiOutlinedInput-root {
    border-radius: 0.5vw!important;
    .MuiOutlinedInput-input {
      padding: 0rem !important;
      color: $mediumBlue;
    }
  }
  fieldset {
    border: 0px solid $ligthGrey3;
  }
}
.countCharacters {
  float: right;
  font-size: 75%;
  margin-top: 5px;
  width: 50px;
  text-align: right;
}

.radioGroupWrapper {
  color: $ligthGrey1;
  font-family: $fontMedium;
  svg path {
    fill: $ligthGrey3;
    stroke: $white !important;
  }
  .Mui-checked svg path {
    fill: $lightBlue;
    stroke: $white !important;
  }
  input {
    margin-bottom: 0;
  }
  .copy {
    flex: 0 1 90%;
  }
}

.radioGroup {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $ligthGreyBg;
  .MuiFormGroup-root {
    margin-left: 1rem;
    width: 145px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.radioGroup:nth-of-type(even) {
  background-color: $white !important;
}

.MuiInputBase-root {
  box-shadow: none;
  outline: none;
  &:hover {
    box-shadow: none;
    outline: none;
    .MuiOutlinedInput-notchedOutline {
      border-color: $ligthGrey3 !important;
    }
  }
  &:focus {
    border-color: $lightBlue;
    box-shadow: none;
    outline: none;
  }
}

.MuiOutlinedInput-root {
  border-radius: 0px !important;
  .MuiOutlinedInput-input {
    padding: 0.6rem 0.6rem !important;
  }
  .MuiSelect-outlined {
    padding: 0.6rem 0.6rem !important;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $lightBlue !important;
    border-width: 1px !important;
  }
}

.MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1) !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiIconButton-colorPrimary {
  &:hover {
    background-color: transparent !important;
  }
  &.Mui-checked:hover {
    background-color: transparent !important;
  }
}
.MuiIconButton-colorSecondary:hover {
  &:hover {
    background-color: transparent !important;
  }
  &.Mui-checked:hover {
    background-color: transparent !important;
  }
}
.MuiFormControl-root {
  width: 100%;
}

.inputNumberFormatted input {
  padding: 0.55rem 0.6rem !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  border-color: rgba(0, 0, 0, 0.23);
  background-color: transparent;
  &:focus {
    border-color: $lightBlue;
    box-shadow: none;
    outline: none;
  }
}

.summaryContainer {
  .MuiFormControl-root {
    input {
      min-height: 2rem;
    }
    .MuiSelect-outlined {
      // padding: .25rem .5rem;
      font-size: 1rem;
      color: $mediumBlue;
      width: 95%;
      display: block;
      border-radius: 0.5vw;
      border: 0px solid $ligthGrey3;
      background-color: $ligthGreyBg;
      box-sizing: border-box;
    }
    
    .MuiSelect-outlined:focus {
      outline: none;
    }
    
    .MuiSelect-outlined.error {
      border-color: $error;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      padding: 0!important;
      border-width: 0px!important;
    }

    .MuiOutlinedInput-root.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-width: 0px !important;
      }
    }
    
  }
}

.attributesContainer {
  .hide {
    display: none!important;
  }
  input {
    min-height: 2rem;
  }
  .MuiFormControl-root {
    min-height: 2rem;
    .MuiOutlinedInput-input, .MuiSelect-outlined {
      padding: .25rem .5rem!important;
      font-size: 1rem;
      color: $mediumBlue;
      width: 95%;
      display: block;
      border-radius: 0.5vw;
      border: 0px solid $ligthGrey3;
      background-color: $ligthGreyBg;
      box-sizing: border-box;
      min-height: 2rem;
    }
    
    .MuiSelect-outlined:focus {
      outline: none;
    }
    
    .MuiSelect-outlined.error {
      border-color: $error;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      padding: 0!important;
      border-width: 0px!important;
    }

    .MuiOutlinedInput-root.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-width: 0px !important;
      }
    }

  }
}



.filtersInnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include media-min($sm) {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    justify-content: space-between;
    align-items: baseline;
  }
  .filtersColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: .25rem;
    }
    justify-content: flex-start;
    align-items: baseline;
    @include media-min($xs) {
    }
    @include media-min($sm) {
      margin-bottom: 0;
    }
    @include media-min($md) {
    }
    .header {
      margin-bottom: 0.25rem;
      font-family: $fontMedium;
      font-size: 0.85rem;
      color: $darkBlue;
    }
    .inputWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      // justify-content: flex-start;
      justify-content: space-between;
      align-items: baseline;
      .inputInnerWrapper {
        width: 100%;
        input {
          box-sizing: border-box;
          border-radius: 0;
          padding: 0.5rem 0.5rem;
          &:focus {
            border-color: $lightBlue;
            box-shadow: none;
            outline: none;
          }
          &#date_of_birth {
            padding: 1.25rem 0.5rem;
          }
        }
        .error {
          &.text-input,
          &.textarea,
          &.select {
            fieldset {
              border: 1px solid $error;
            }
          }
        }
      }
    }
    &:last-of-type {
      margin-right: 0rem!important;
    }
  }
}

.investorspageBodyTopWrapper {
  .column2 {
    width: 100%;
    @include media-min($xs) {
    }
    @include media-min($sm) {
      width: 200%;
    }
    @include media-min($md) {
    }
    @include media-min($sm) {
      margin-left: 2rem;
      margin-right: 0rem;
    }
    .inputWrapper {
      .inputInnerWrapperSplitter {
        display: block;
        width: 2rem;
      }
    }
  }
}

.companiespageBodyTopWrapper {
  .column2 {
    width: 100%;
    @include media-min($xs) {
    }
    @include media-min($sm) {
      width: 200%;
    }
    @include media-min($md) {
    }
    margin-left: 2rem;
    margin-right: 2rem;
    .inputWrapper {
      .inputInnerWrapperSplitter {
        display: block;
        width: 2rem;
      }
    }
  }
}

.investmentspageBodyTopWrapper {
  .column2 {
    width: 100%;
    @include media-min($sm) {
      margin-left: 2rem;
      margin-right: 0rem;
    }
    .inputWrapper {
      .inputInnerWrapperSplitter {
        display: block;
        width: 2rem;
      }
    }
  }
  .column3 {
    width: 100%;
    @include media-min($sm) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
    .inputWrapper {
      .inputInnerWrapperSplitter {
        display: block;
        width: 2rem;
      }
    }
  }
}