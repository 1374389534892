// COMMON STYLES
html {
  font-size: 3.5vw;
  @include media-min($xs) {
    font-size: 2.75vw;
  }
  @include media-min($sm) {
    font-size: 2vw;
  }
  @include media-min($md) {
    font-size: 1.5vw;
  }
  @include media-min($lg) {
    font-size: 1.35vw;
  }
  @include media-min($xl) {
    font-size: 1.25vw;
  }
  @include media-min($xxl) {
    font-size: 16.125px;
  }
}

body {
  margin: 0;
  font-family: $fontLight;
  font-size: 1rem;
  color: $darkBlue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-top: 3rem;
  color: $darkBlue;
}

p {
  font-family: $fontLight;
  font-size: 1rem;
  line-height: 1.4rem;
  color: $darkBlue;
}

a {
  font-family: $fontLight;
  font-size: 1rem;
  color: $lightBlue;
  text-decoration: none;
  cursor: pointer;
}

.pageWrapper {
  .pageBodyTopWrapper {
    padding: 2vw 5vw;
    @media (min-width: 1400px) {
      padding: 1vw 8vw;
      max-width: 1400px;
      margin: 0 auto;
    }
    background-color: $white;
  }
  .pageBodyBottomWrapper {
    font-family: $fontMedium;
    padding: 5vw 5vw;
    @media (min-width: 1400px) {
      padding: 4vw 8vw;
      .pageBodyBottomInnerWrapper {
        max-width: 1400px;
        margin: 0 auto;
      }
    }
    background-color: $ligthGreyBg;
    .MuiBox-root {
      padding: 0px!important;
    }
  }
}
