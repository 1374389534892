.pageWrapper.investmentsListPage {
  .investmentspageBodyTopWrapper {
    width: 100%;
    .investmentFilters {
      .filtersColumn {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0rem;
        }
        @include media-min($sm) {
          margin-bottom: .25rem;
        }
        justify-content: flex-start;
        align-items: baseline;
      }
    }
  }

  .highlights {
    @include media-min($md) {
      max-width: 80%;
    }
    .highlightsColumn {
      margin-bottom: 1rem;
      min-width: 33%;
      &.column3 {
        width: 75%;
        order: 6;
        .subtitlesWrapper {
          width: 75%;
        }
      }
      @include media-min($xs) {
        min-width: 33%;
      }
      @include media-min($sm) {

      }
      @include media-min($md) {
        min-width: auto;
        &.column3 {
          width: 35%;
          order: 3;
          .subtitlesWrapper {
            width: 85%;
          }
        }
      }
    }
  }

  // List Styles
  .investment-table {
    .grid-table-row {
      .grid-table-cell {
        &:first-child {
          display: none;
        }
      }

      .company {
        p {
          margin: 0rem;
          @include media-min($xs) {
            width: 9rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @include media-min($sm) {
            width: 15rem;
          }
          @include media-min($md) {
            width: inherit;
            white-space: normal;
          }
          &:last-child {
            display: none;
          }
        }
      }

      @media (min-width: 800px) {
        grid-template-columns: 1fr 4fr repeat(6, 2fr);

        .grid-table-cell {
          &:first-child {
            display: block;
            text-align: center;
            padding: 0px;

            img {
              height: 80%;
              width: 80%;
              max-height: 4rem;
              max-width: 4rem;
            }
          }

          &:nth-child(2) {
            text-align: start;
            padding: 0px;
            justify-self: left;
          }
        }

        .company {
          p {
            margin: 0.1rem;

            &:first-child {
              color: $lightBlue;
              font-size: 1.2rem;
              font-weight: bolder;
            }

            &:last-child {
              display: block;
              color: $mediumBlue;
              font-size: 0.75rem;
              // font-weight: bolder;
            }
          }
        }
      }
    }
  }

  .investmentCompanyContainer {
    display: flex;

    .left {
      height: 10vw;
      max-height: 175px;
      width: 10vw;
      // max-width: 175px;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 175px;
        max-width: 175px;
      }
    }

    .right {
      padding: 1vw;

      .companyName {
        color: #00aeef;
        margin: 0;
      }

      .companySector {
        margin: 0.25rem 0;
      }

      .companyLink {
        color: #00aeef;
      }
    }
  }
}
