.pageWrapper.investorPage {
  // General
  .header {
    display: block;

    .investorDescriptionContainer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 1rem;
      @media (min-width: 800px) {
        padding-bottom: 0;
        flex-direction: row;
      }
      .left {
        display: none;
        @media (min-width: 800px) {
          height: 10vw;
          max-height: 175px;
          width: 10vw;
          min-width: 10vw;
          // max-width: 175px;
          background-color: #f8f8f8;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 175px;
            max-width: 9vw;
            max-width: 175px;
          }
        }
      }
    
      .right {
        padding: 5vw 0 0 4vw;
        @media (min-width: 800px) {
          padding: 1vw;
        }
        .investorName {
          display: flex;
          flex-direction: column;
          justify-content: left;
          align-items: left;
          @media (min-width: 600px) {
            flex-direction: row;
            .space::after {
              content: ' ';
              white-space: pre;
            }
          }
        }

        .investorDescription {
          max-width: 90vw;
          @media (min-width: 800px) {
            max-width: 50vw;
          }
        }

        .companyName {
          color: #00aeef;
          margin: 0;
        }
    
        .companySector {
          margin: 0.25rem 0;
        }
    
        .companyLink {
          color: #00aeef;
        }
      }
    }
    .summaryContainer {
      width: 100%;
      display: block;
      box-sizing: border-box;
      padding: 1.5rem 0 0 4vw;
      @include media-min($md) {
        padding: 1.5rem 0 0 11vw;
      }
      .highlights {
        margin-top: 0rem;
        .highlightsColumn {
          margin-bottom: 1rem;
          @include media-min($sm) {
            min-width: 33%;
          }
          @include media-min($md) {
            min-width: inherit;
          }
          &.column1 {
            order: 1;
          }
          &.column2 {
            order: 2;
          }
          &.column3 {
            order: 3;
            @include media-min($xxl) { 
              max-width: 400px;
            }
          }
          &.column4 {
            order: 7;
            min-width: 100%;
            @include media-min($xs) { }
            @include media-min($sm) {
              order: 4;
              min-width: 50%;
            }
            @include media-min($md) { 
              min-width: inherit;
            }
            @include media-min($lg) { }
            .header {
              max-width: inherit;
            }
            .subtitlesWrapper {
              width: 55%;
              @include media-min($sm) {
                width: 70%;
              }
              @include media-min($md) { 
                width: 17vw;
              }
            }
          }
          &.column5 {
            order: 5;
            min-width: 25%;
            @include media-min($sm) {
              .header:after {
                content:"\A  ";
                white-space: pre;
                min-width: 25%;
              }
            }
            @include media-min($md) { 
              min-width: inherit;
            }
          }
          &.column6 {
            order: 6;
            min-width: 25%;
            @include media-min($sm) {
              .header:after {
                content:"\A  ";
                white-space: pre;
                min-width: 25%;
              }
            }
            @include media-min($md) { 
              min-width: inherit;
            }
          }
        }
        input {
          margin-top: .75rem;
        }
      }
    }
  }

  .tabsWrapper {
    padding-bottom: 4vw;
    .MuiTabs-indicator {
      background-color: #00aeef!important;
    }
    .MuiTab-textColorPrimary.Mui-selected {
      color: #00aeef;
    }
    button:active,
    button:hover {
      background: rgba(0, 0, 0, 0.08);
      border: 0 solid rgba(0, 0, 0, 0.08);
    }
  }

  .attributesContainer {
    padding: 1.5rem 0 1.5rem 5vw;
    @include media-min($md) { 
      padding: 1.5rem 11vw;
    }
  }

  .attributeGrid {
    width: 100%;
    display: inline-grid;
    align-content: center;
    grid-column-gap: 3vw;
    grid-row-gap: 1vw;

    grid-template-columns: repeat(1, minmax(90%, 1fr));
    
    .attributesContainer {
      padding: 1.5rem 0vw 1.5rem 11vw;
    }
    
    @include media-min(600px) {
      grid-template-columns: repeat(2, minmax(50%, 1fr));
    }

    @media (min-width: 800px) {
      grid-template-columns: repeat(4, minmax(25%, 1fr));
    }
  }

  // Text Button
  .textButton {
    margin: 1.5rem 0 1.5rem 5vw;
    @include media-min($md) { 
      margin: 1.5rem 11vw;
    }

    font-weight: bold;
    color: #1dacf5;
    cursor: pointer;
    width: fit-content;
    box-sizing: content-box;

    span {
      font-size: 1.25rem;
    }
  }
}