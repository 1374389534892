// General
.pageWrapper.companyListPage {
  .highlights {
    @include media-min($md) {
      max-width: 75%;
    }
    .highlightsColumn {
      min-width: 50%;
      margin-bottom: 1rem;
      &.column3 {
        width: 75%;
        order: 6;
      }
      @include media-min($xs) {
        min-width: 50%;
      }
      @include media-min($sm) {
        min-width: 33%;
        &.column3 {
          width: 50%;
        }
      }
      @include media-min($md) {
        min-width: auto;
        &.column3 {
          width: inherit;
          order: 3;
        }
      }
      @include media-min($xxl) { 
        &.column3 {
        max-width: 400px;
        }
      }
    }
  }
  
  .tableCompanyList {
    .grid-table {

      .grid-table-row:first-of-type {
        .grid-table-cell:first-of-type {
          display: block;
          width: 3rem;
          min-width: 3rem;
          max-width: 3rem;
        }
        .grid-table-cell:nth-child(2) {
          justify-self: left!important;
          text-align: left;
          padding: 2rem 0.5rem 2rem 0rem;
        }
      }

      &-row {
        @media (min-width: 800px) {
          grid-template-columns: 1fr repeat(6, 3fr) 3fr;
        }
      }
      &-cell {
        &:first-child {
          &.companyLogo {
            display: none;
          }
        }
        @media (min-width: 800px) {
          &:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
            border-bottom: 0;
            &.companyLogo {
              display: block;
              width: 3rem;
              min-width: 3rem;
              max-width: 3rem;
              img {
                height: 90%;
                width: 90%;
                max-height: 4rem;
                max-width: 4rem;
              }
            }
          }
          &:nth-child(2) {
            text-align: left;
          }
          &:last-child {
            padding-right: 0;
            grid-column-start: 8;
            grid-column-end: 9;
            border-bottom: 0;
          }
        }
      }
    }
  }
  
  // Chart Mode
  .tableCompanyListChart {
    .grid-table {
      &-row {
        grid-template-columns: 1fr 3fr;
      }
  
      &-cell {
        @media (min-width: 800px) {
          &:first-child {
            grid-column-start: 1;
            grid-column-end: 2;
          }
          &:last-child {
            grid-column-start: 2;
            grid-column-end: 3;
          }
        }
      }
  
      .grid-table-row:first-of-type {
        .grid-table-cell {
          text-align: left;
        }
      }
  
      .grid-table-cell:not(:first-of-type) {
        padding: 0rem 0.5rem 0rem 0;
        .barsWrapper {
          .barInvested {
            position: relative;
            background-color: #8fadc7;
            height: 0.75rem;
            width: 0vw;
            margin-bottom: 0.25rem;
            .value {
              font-family: "CeraProMedium";
              position: absolute;
              color: #8fadc7;
              top: -.25rem;
              margin-left: calc(100% + 0.5rem)
            }
          }
          .barValue {
            position: relative;
            background-color: #1daef0;
            height: 0.75rem;
            width: 0vw;
            .value {
              font-family: "CeraProMedium";
              position: absolute;
              color: #1daef0;
              top: -.25rem;
              margin-left: calc(100% + 0.5rem)
            }
          }
        }
      }
  
      .listItem.companyListItemChart {
        .grid-table-cell:not(:first-of-type) {
          border-left: 1px solid #ddd;
        }
      }
  
      .yaxis {
        width: 9%;
        display: inline-block;
      }
    }
  }
}
