.allocation-table {
  background-color: white;
  padding: 3rem 3rem 0 3rem;

  @media (min-width: 800px) {
    padding: 0 3rem;
  }

  .grid-table-row {
    .company {
      text-align: -webkit-center;
      p {
        color: $lightBlue;
        font-weight: bolder;
        margin: 0rem;
        @include media-min($xs) {
          width: 9rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include media-min($sm) {
          width: 15rem;
        }
        @include media-min($md) {
          width: inherit;
          white-space: normal;
        }
        &:last-child {
          display: none;
        }
      }
    }

    @media (min-width: 400px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: 2fr repeat(6, 1fr);

      .company {
        p {
          margin: 0.1rem;

          &:first-child {
            font-size: 1.2rem;
          }

          &:last-child {
            display: block;
            color: $mediumBlue;
            font-size: 0.75rem;
            // font-weight: bolder;
          }
        }
      }
    }
  }

  .grid-table-cell {
    &:before {
      background-color: rgba(244, 244, 244, 1);
      border: 2px solid #ffffff;
    }
    
    &:last-of-type {
      font-size: 1rem;
      margin-top: 0.75rem;
      &:before {
        display: block;
      }
    }

    .allocation-investor {
      margin: 0;
    }
    .allocation-address {
      display: none;
    }

    &:first-child,
    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:last-child:before {
      display: none;
    }

    @media (min-width: 800px) {
      &:last-of-type {
        // font-size: .8rem;
        margin-top: 0rem;
        &:before {
          display: none;
        }
      }
      margin-bottom: 0rem;
      &:not(:last-child) {
        border-right: 0px solid #ddd;
      }
      &:first-of-type {
        text-align: left;
        justify-self: start;
        flex-direction: row-reverse;
      }
      text-align: right;
      padding: 2rem 0.5rem 2rem 0;
      &:before {
        content: attr(data-title);
        display: none;
      }
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        border-bottom: 0;
        padding: 0px;
      }
      &:last-child {
        padding-right: 0;
        grid-column-start: 7;
        grid-column-end: 8;
        border-bottom: 0;
      }
      .allocation-investor {
        font-family: $fontMedium;
        font-size: 1.25rem;
        color: $lightBlue;
      }
      .allocation-address {
        display: block;
        margin: 0;
        font-size: 0.75rem;
      }
    }
  }

  // Pagination Config
  .tablePagination {
    padding: 2rem 0;
  }
}

.allocation-table.invested-mode {
  .grid-table-row {
    .grid-table-cell {
      &:first-child,
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @media (min-width: 800px) {
      grid-template-columns: 5fr repeat(3, 2fr);
      column-gap: 1vw;

      .grid-table-cell {
        &:first-child {
          display: block;
          text-align: start;
          justify-self: left;
          padding: 0px;
          grid-column-start: 1;
          grid-column-end: 2;
        }

        &:last-child {
          padding-right: 0;
          grid-column-start: 4;
          grid-column-end: 5;
          border-bottom: 0;
        }
      }
    }
  }
}

.allocation-table.investor-mode {
  .grid-table-row {
    .grid-table-cell {
      &:first-child {
        display: none;
      }

      &:nth-child(2),
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @media (min-width: 800px) {
      grid-template-columns: 6vw 4fr repeat(5, 2fr);
      column-gap: 1vw;

      .grid-table-cell {
        &:first-child {
          display: block;
          text-align: center;
          padding: 0px;

          img {
            height: 90%;
            width: 90%;
            max-height: 4rem;
            max-width: 4rem;
          }
        }

        &:nth-child(2) {
          text-align: start;
          padding: 0px;
          justify-self: left;
          grid-column-start: 2;
          grid-column-end: 3;
        }

        &:last-child {
          padding-right: 0;
          grid-column-start: 7;
          grid-column-end: 8;
          border-bottom: 0;
        }
      }
    }
  }
}

// Allocation Filter Styles
.allocationspageBodyTopWrapper {
  width: 100%;
  .allocationFilters {
    display: grid;

    @media (min-width: 400px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: repeat(4, 1fr);
    }

    column-gap: 1vw;
    .filtersColumn {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      justify-content: flex-start;
      align-items: baseline;
    }
  }
}

.actionsTopMenuAllocations {
  .formContainer {
    .columnsWrap {
      input, .MuiSelect-outlined {
        width: 100%;
      }
      @media (min-width: 600px) {
        input, .MuiSelect-outlined {
          width: 95%;
        }
        display: flex;
        .columnLeft {
          flex: 50%;
        }
        .columnRight {
          flex: 50%;
        }
      }
      .attributeContainer {
        margin-bottom: 1.5rem!important;
      }
    }
    .header {
      grid-row: 1;
      display: block;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      margin: 2vh 0 .5vh;
    }
    .MuiSelect-iconOutlined {
      right: 1rem!important;
    }
  }
  .MuiDialog-paperWidthLg {
    width: 75vw;
    @include media-min($lg) {
      width: 50vw;
    }
  }
  #form-dialog-title {
    margin-top: 1rem;
  }
  .MuiDialogActions-root {
    &:first-child {
      padding-top: 0;
    }
    padding: 0 2rem 2rem 2rem!important;
    .textButton span {
      font-weight: bold;
    }
  }
  .MuiAutocomplete-endAdornment {
    button:hover {
      background: transparent;
      border: 0px!important;
      transition: all 200ms linear;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 2rem;
  }
  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0px;
      width: 95%;
    }
  }
}