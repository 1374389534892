// buttons
button {
  margin: 1vw 0;
  padding: 1rem;
  border: 0.2vw solid $lightBlue;
  border-radius: 0;
  background-color: $lightBlue;
  box-shadow: none;
  font-size: 1rem;
  color: $white;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  font-family: $fontMedium;
  &:hover {
    background: darken($lightBlue, 8%);
    border: 0.2vw solid darken($lightBlue, 8%);
    transition: all 200ms linear;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &:active {
    background: darken($lightBlue, 25%);
  }
}

button + button,
button + a {
  margin: 0 0.5rem;
}

button.outline {
  background-color: $ligthGrey3;
  border: 1px solid $ligthGrey2;
  color: $ligthGrey1;
}
button.outlineBlue {
  background-color: $white;
  border: 2px solid $lightBlue;
  color: $lightBlue;
}
button.outlineBlueSmall {
  background-color: $white;
  border: 2px solid $lightBlue;
  color: $lightBlue;
  padding: 8px 16px !important;
  &.disable {
    opacity: 0;
    cursor: default;
  }
}
.backButtonWrapper {
  text-align: center;
  button.backButton {
    background-color: $white;
    border: 0px solid $lightBlue;
    color: $lightBlue;
    margin: auto;
  }
}

.textButton {
  cursor: pointer;
}


.buttonDelete {
  margin: 1vw 0;
  padding: 1rem;
  border: 0.2vw solid red;
  border-radius: 0;
  background-color: white;
  box-shadow: none;
  font-size: 1rem;
  color: red;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  font-family: $fontMedium;
  margin-left: 1rem;
  &:hover {
    color: #ffffff;
    background: darken(red, 8%);
    border: 0.2vw solid darken(red, 8%);
    transition: all 200ms linear;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &:active {
    background: darken(red, 25%);
  }

  &.small {
    margin-left: 0rem;
    border: 0vw solid red;
    font-family: $fontRegular;
  }
  &.small:hover {
    color: red;
    background: #ffffff;
    border: 0vw solid darken(red, 8%);
    transition: all 200ms linear;
  }
  &.small:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &.small:active {
    background: #ffffff;
  }
}