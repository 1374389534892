// fonts
@font-face {
  font-family: "CeraProBlack";
  src: url("../fonts/CeraProBlack/CeraProBlack.eot");
  src: url("../fonts/CeraProBlack/CeraProBlack.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/CeraProBlack/CeraProBlack.woff2") format("woff2"),
    url("../fonts/CeraProBlack/CeraProBlack.woff") format("woff"),
    url("../fonts/CeraProBlack/CeraProBlack.ttf") format("truetype"),
    url("../fonts/CeraProBlack/CeraProBlack.svg#CeraProBlack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraProBold";
  src: url("../fonts/CeraProBold/CeraProBold.eot");
  src: url("../fonts/CeraProBold/CeraProBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/CeraProBold/CeraProBold.woff2") format("woff2"),
    url("../fonts/CeraProBold/CeraProBold.woff") format("woff"),
    url("../fonts/CeraProBold/CeraProBold.ttf") format("truetype"),
    url("../fonts/CeraProBold/CeraProBold.svg#CeraProBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraProMedium";
  src: url("../fonts/CeraProMedium/CeraProMedium.eot");
  src: url("../fonts/CeraProMedium/CeraProMedium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/CeraProMedium/CeraProMedium.woff2") format("woff2"),
    url("../fonts/CeraProMedium/CeraProMedium.woff") format("woff"),
    url("../fonts/CeraProMedium/CeraProMedium.ttf") format("truetype"),
    url("../fonts/CeraProMedium/CeraProMedium.svg#CeraProMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraProRegular";
  src: url("../fonts/CeraProRegular/CeraProRegular.eot");
  src: url("../fonts/CeraProRegular/CeraProRegular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/CeraProRegular/CeraProRegular.woff2") format("woff2"),
    url("../fonts/CeraProRegular/CeraProRegular.woff") format("woff"),
    url("../fonts/CeraProRegular/CeraProRegular.ttf") format("truetype"),
    url("../fonts/CeraProRegular/CeraProRegular.svg#CeraProRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraProLight";
  src: url("../fonts/CeraProLight/CeraProLight.eot");
  src: url("../fonts/CeraProLight/CeraProLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/CeraProLight/CeraProLight.woff2") format("woff2"),
    url("../fonts/CeraProLight/CeraProLight.woff") format("woff"),
    url("../fonts/CeraProLight/CeraProLight.ttf") format("truetype"),
    url("../fonts/CeraProLight/CeraProLight.svg#CeraProLight") format("svg");
  font-weight: normal;
  font-style: normal;
}

$fontBlack: "CeraProBlack", -apple-system, BlinkMacSystemFont, sans-serif;
$fontBold: "CeraProBold", -apple-system, BlinkMacSystemFont, sans-serif;
$fontMedium: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
$fontRegular: "CeraProRegular", -apple-system, BlinkMacSystemFont, sans-serif;
$fontLight: "CeraProLight", -apple-system, BlinkMacSystemFont, sans-serif;

// colors
$white: #ffffff;
$black: #000000;

$lightBlue: #00aeef;
$mediumBlue: #586a7a;
$darkBlue: #22292f;

$ligthGrey1: #757575;
$ligthGrey2: #aaaaaa;
$ligthGrey3: #cccccc;
$ligthGreyBg: #f4f4f4;

$textOnWhite1: #22292f;
$textOnWhite2: $ligthGrey1;
$textOnBlack1: #ffffff;
$textOnBlack2: $ligthGrey3;

$orange: #fcad28;
$springGreen: #06d89b;
$pink: #e64980;
$violet: #9c78b5;

$error: red;

// default breakpoints
$xs: "480px";
$sm: "600px";
$md: "799px";
$lg: "1010px";
$xl: "1280px";
$xxl: "1400px";

@mixin media-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

// gradients
@mixin boxShadow($color1, $opacity1, $color2, $opacity2) {
  box-shadow: inset 0 1px 1px rgba($color1, $opacity1),
    0 0 0 3px rgba($color2, $opacity2);
}
