// Action Top Menu
.actionsTopMenu {
  padding: 0 0 2rem;
  @include media-min($sm) {
    padding: 1rem 0;
  }
  @include media-min($lg) {
    padding: 0;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  .addNewButton,
  .exportCSV {
    a {
      font-family: $fontMedium;
      cursor: pointer;
    }
  }
}

// Filter Input
.filterInputTitle {
  margin-bottom: 0.25rem;
  font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 0.85rem;
  color: #22292f;
  grid-row: 1;
}

.filterInputWrapper {
  grid-row: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  justify-content: space-between;
  align-items: baseline;
  .inputInnerWrapper {
    width: 100%;
    input {
      box-sizing: border-box;
      border-radius: 0;
      padding: 0.5rem 0.5rem;
      &:focus {
        border-color: #00aeef;
        box-shadow: none;
        outline: none;
      }
      &#date_of_birth {
        padding: 1.25rem 0.5rem;
      }
    }
    .error {
      &.text-input,
      &.textarea,
      &.select {
        fieldset {
          border: 1px solid red;
        }
      }
    }
  }
}
