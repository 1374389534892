.tableHidden {
  display: none;
}

.grid-table {
  display: grid;
  margin: 0 auto;
  align-items: center;
  background: transparent;
  font-family: $fontLight;
  // font-size: .85rem;
  grid-row-gap: 2rem;

  @media (min-width: 800px) {
    grid-row-gap: 0rem;
  }

  &-row {
    &:first-of-type {
      font-family: $fontMedium;
    }
    display: grid;
    align-items: center;

    grid-template-columns: 1fr 1fr;

    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 800px) {
      grid-template-columns: 5fr repeat(4, 3fr) repeat(3, 2fr);
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }

    &:first-child {
      display: none;
      @media (min-width: 800px) {
        display: grid;
      }
    }
  }

  &-cell {
    align-items: center;
    text-align: center;
    margin-bottom: 0.75rem;
    &:last-of-type {
      font-size: 1rem;
      margin-top: 0.75rem;
      &:before {
        display: none;
      }
    }

    // padding: 0 0 1rem 0;

    &:before {
      content: attr(data-title);
      font-weight: bold;
      display: block;
      background: rgba(#ddd, 0.8);
      border: 2px solid #f4f4f4;
      padding: 0.75rem;
      margin-bottom: 0.75rem;
      // font-size: 1rem;
    }

    &:not(:last-child) {
      // border-right: 1px solid #ddd;
    }

    &:first-child,
    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media (min-width: 500px) {
      &:first-child,
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 4;
        // border-bottom: 1px solid #ddd;
      }
    }

    @media (min-width: 800px) {
      &:last-of-type {
        // font-size: .8rem;
        margin-top: 0rem;
        &:before {
          display: none;
        }
      }
      margin-bottom: 0rem;
      &:not(:last-child) {
        border-right: 0px solid #ddd;
      }
      &:first-of-type {
        text-align: left;
        justify-self: left!important;
      }
      text-align: right;
      padding: 2rem 0.5rem 2rem 0.5rem;
      &:before {
        content: attr(data-title);
        display: none;
      }
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        border-bottom: 0;
      }
      &:last-child {
        padding-right: 0;
        grid-column-start: 8;
        grid-column-end: 9;
        border-bottom: 0;
      }
    }
  }

  &-cell.no-sort {
    color: $ligthGrey1;
  }

  &-cell.sortable {
    cursor: pointer;
    display: flex;
    justify-self: right;
  }
}

// Pagination
.tablePagination {
  width: 100%;
  padding: 4rem 0;
  margin: 0 auto;
  text-align: center;
  nav {
    display: flex;
    margin: 1vw auto;
    padding: 1rem 0;
    background: none;
    .MuiPagination-ul {
      margin: 0 auto;
      button:hover {
        border: none;
      }
    }
  }
}
.tableSwitchWrap {
  position: relative;
  width: 100%;
  // Table Switch
  .tableSwitch {
    width: 12rem;
    padding: 1rem 0 3rem;
    @include media-min($lg) {
      padding: 1rem 0 2rem;
    }
    margin: 0 auto;
    text-align: center;
    .MuiTypography-body1 {
      margin: auto;
    }
    .MuiSwitch-root {
      overflow: visible;
      width: 30px;
      height: 16px;
      padding: 0px;
      display: flex;
      .MuiSwitch-switchBase {
        padding: 3px;
        color: #ffffff;
        &.Mui-checked {
          transform: translateX(12px);
          color: #ffffff;
          opacity: 1;
          background-color: #22292f !important;
          border-color: #22292f !important;
          + .MuiSwitch-track {
            opacity: 1;
            background-color: #22292f !important;
            border: 1px solid #22292f !important;
          }
        }
      }
      .MuiSwitch-thumb {
        width: 12px;
        height: 12px;
        box-shadow: none;
      }
      .MuiSwitch-track {
        border: 1px solid #c6d3de;
        border-radius: 10px !important;
        opacity: 1;
        background-color: #c6d3de !important;
      }
    }
  }

  .tableSwitchlabels {
    display: none;
    position: absolute;
    top: .5rem;
    right: 0;
    @include media-min($xs) {
      &.tableChart {
        display: block;
      }
    }
    span {
      display: inline-block;
      width: 1.5rem;
      height: .5rem;
      margin-right: .5rem;
    }
    .investedLabel {
      span {
        background-color: #8fadc7;
      }
    }
    .investedValue {
      span {
        background-color: #1daef0;
      }
    }
  }
}
