.filters {
  width: 100%;
  margin: 1rem 0;
  @include media-min($sm) {
    margin: 1.5rem 0 2rem;
  }
  @include media-min($lg) {
    margin: 2rem 0;
  }
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  column-gap: 2rem;
}

.filtersMobileWrapper {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  box-shadow: none;
  .MuiAccordionSummary-root {
    min-height: auto;
    &.Mui-expanded {
      min-height: auto;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0.5rem 0 !important;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0.5rem !important;
  }
}

.filtersMobileWrapper {
  display: block;
}
.filtersDesktopWrapper {
  display: none;
}
@media (min-width: 600px) {
  .filtersMobileWrapper {
    display: none;
  }
  .filtersDesktopWrapper {
    display: block;
  }
}
