// Login Form
.loaderWrapper,
.errorMessageWrapper {
  text-align: center;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
    height: 50px;
  }
  &.modalWindow {
    height: 60vh;
  }
}
// Login Form
.warningMessageWrapper {
  text-align: center;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
    height: 50px;
  }
  &.modalWindow {
    height: 60vh;
  }
}

.loginWrap {
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.loginFormWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  .loginFormColumn {
    width: 100% !important;
    max-width: 600px;
    padding: 23vh 0 1rem !important;
    margin: 0 auto !important;
    background-color: $white !important;
    p {
      color: $ligthGrey1;
      font-family: $fontMedium;
    }
    input {
      box-sizing: border-box;
      font-family: $fontMedium;
      font-size: 1.2rem;
      line-height: 1.1;
      letter-spacing: 0;
      width: 100%;
      border-radius: 0;
      border: 1px solid $ligthGrey3;
      background: $white;
      margin: 1rem 0 0;
      padding: 1rem;
      &:focus {
        border-color: $lightBlue;
        box-shadow: none;
        outline: none;
      }
    }
    #password {
      letter-spacing: 0.2rem;
      &::placeholder {
        letter-spacing: 0;
      }
    }
  }
}

.loginFormButtons {
  button {
    width: 100%;
    font-size: 1.15rem;
    max-width: 600px;
  }
}
