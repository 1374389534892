// General
.pageWrapper.investorListPage {
  .highlights {
    .highlightsColumn {
      min-width: 50%;
      margin-bottom: 1rem;
      &.column3 {
        width: 75%;
        order: 6;
      }
      @include media-min($xs) {
        min-width: 50%;
      }
      @include media-min($sm) {
        min-width: 33%;
        &.column3 {
          width: 50%;
        }
      }
      @include media-min($md) {
        min-width: auto;
        &.column3 {
          width: inherit;
          order: 3;
        }
      }
    }
  }
}

// Chart Mode
.tableInvestorListChart {
  .grid-table {
    &-row {
      grid-template-columns: 1fr 3fr;
    }

    &-cell {
      @media (min-width: 800px) {
        &:first-child {
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &:last-child {
          grid-column-start: 2;
          grid-column-end: 3;
        }
      }
    }

    .grid-table-row:first-of-type {
      .grid-table-cell {
        text-align: left;
      }
    }

    .grid-table-cell:not(:first-of-type) {
      padding: 0rem 0.5rem 0rem 0;
      .barsWrapper {
        .barInvested {
          position: relative;
          background-color: #8fadc7;
          height: 0.75rem;
          width: 0vw;
          margin-bottom: 0.25rem;
          .value {
            font-family: "CeraProMedium";
            position: absolute;
            color: #8fadc7;
            top: -.25rem;
            margin-left: calc(100% + 0.5rem)
          }
        }
        .barValue {
          position: relative;
          background-color: #1daef0;
          height: 0.75rem;
          width: 0vw;
          .value {
            font-family: "CeraProMedium";
            position: absolute;
            color: #1daef0;
            top: -.25rem;
            margin-left: calc(100% + 0.5rem)
          }
        }
      }
    }

    .listItem.investorListItemChart {
      .grid-table-cell:not(:first-of-type) {
        border-left: 1px solid #ddd;
      }
    }

    .yaxis {
      width: 9%;
      display: inline-block;
    }
  }
}
