// Navigation Styles

// NavBar
nav {
  display: flex;
  margin: 1vw 5vw;
  @media (min-width: 1400px) {
    padding: 1vw 8vw;
    max-width: 1400px;
    margin: 1vw auto;
  }
  padding: 1rem 0;
  background: $white;

  .logoWrapper {
    display: flex;
    width: 100%;
    img {
      width: 11rem;
      max-width: 164px;
    }
  }

  .mobileMenuWrapper {
    display: block;
    @media (min-width: 600px) {
      display: none;
    }
    button.MuiIconButton-root:hover {
      background: #ffffff;
      border: 0px;
      transition: none;
    }
  }

  .desktopMenuWrapper {
    display: none;
    @media (min-width: 600px) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      a {
        font-family: $fontRegular;
        text-transform: capitalize;
        line-height: 1.85rem;
        padding: 0rem 1.5vw;
        border-radius: 0.5vw;
        color: $darkBlue;
        &.selected {
          color: $orange;
        }
        &:last-of-type {
          padding: 0rem;
        }
      }
    }
  }
}

#mobile-menu {
  .MuiPaper-root {
    box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.1)!important;
    ul.MuiMenu-list {
      a {
        li.MuiMenuItem-root {
          font-family: "CeraProRegular"!important;
          text-transform: capitalize;
          line-height: 1.85rem!important;
          padding: 0rem 2rem!important;
          border-radius: 0.5vw!important;
          color: #22292f!important;
        }
        &.selected {
          li.MuiMenuItem-root {
            color: #fcad28!important;
          }
        }
      }
    }
  }
}
