// Attribute Items
.investorPage, .investmentPage, .companyPage {
  .attributeContainer {
    .header {
      grid-row: 1;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      margin: 1vh 0;
    }
  
    .value {
      grid-row: 2;
      display: block;
      margin: 1vh 0;
      text-overflow: ellipsis;
      overflow: hidden; 
    }
  }
  .subtitlesWrapper {
    .attributeContainer {
      .header {
        margin: 0;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden; 
      }
    }
  }
}

