.actionsBottomMenu {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  span.divider {
    font-weight: 100;
    color: #00aeef;
    display: inline-block;
    margin: 0 1rem;
  }
}

.actionsBottomMenuAllocations {
  .formContainer {
    .columnsWrap {
      input, .MuiSelect-outlined {
        width: 100%;
      }
      @media (min-width: 600px) {
        input, .MuiSelect-outlined {
          width: 95%;
        }
        display: flex;
        .columnLeft {
          flex: 50%;
        }
        .columnRight {
          flex: 50%;
        }
      }
      .attributeContainer {
        margin-bottom: .25rem !important;
      }
    }
    .header {
      grid-row: 1;
      display: block;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      margin: 1.25vh 0 .5vh;
    }
    .MuiSelect-iconOutlined {
      right: 1rem!important;
    }
  }
  .MuiDialog-paperWidthLg {
    width: 75vw;
    @include media-min($lg) {
      width: 50vw;
    }
  }
  #form-dialog-title {
    margin-top: 1rem;
  }
  .MuiDialogActions-root {
    &:first-child {
      padding-top: 0;
    }
    padding: 0 2rem 2rem 2rem!important;
    .textButton span {
      font-weight: bold;
    }
  }
  .MuiAutocomplete-endAdornment {
    button:hover {
      background: transparent;
      border: 0px!important;
      transition: all 200ms linear;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 2rem;
  }
  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0px;
      width: 95%;
    }
  }

  .investorArea {
    min-height: 125px;
    width: 95%;
    background-color: #f8f8f8;
    padding: 1rem;
    box-sizing: border-box;
  }
  .newAllocation {
    margin: .45em 0 0 0;
    // Table Switch
    .tableSwitch {
      width: 12rem;
      padding: 0rem;
      margin: 0 auto;
      text-align: center;
    }
    label {
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      font-weight: 100;
      margin: 0rem 0 .25rem 0;
      &.totalPrice {
        margin: .5rem 0 .25rem 0;
      }
    }
    .investmentAmountWrap,
    .totalPriceWrap.pounds {
      position: relative;
      input {
        padding-left: 1.25rem;
      }
      .pound {
        position: absolute;
        top: .25rem;
        left: .25rem;
        font-size: 1.25rem;
        font-weight: bold;
        color: #5e707f;
        margin-right: .5rem;
      }
    }
    .totalPriceWrap {
      input {
        background-color: #c6d3de;
      }
    }
  }
}

.actionsBottomMenuInvestments {
  width: 100%;
  .MuiDialog-container {
    .MuiDataGrid-columnSeparator {
      display: none;
    }
    .MuiDialog-paperWidthLg {
      overflow: hidden;
      width: 80vw !important;
    }
    .MuiDialogActions-root {
      padding: 1rem 2rem;
    }
  }
  .targetsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 1.25rem;
    .space {
      min-width: 3rem;
    }
    .header {
      display: block;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: .85rem;
      line-height: .85rem;
      margin-bottom: .35rem;
    }
    .inputWrapper {
      display: block;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: #00aeef;
      text-align: right;
      &.maxExceed {
        color: $error!important;
      }
    }
    .searchWrapper {
      min-width: 20rem;
      .totalMaxFundSubscription {
        &.maxReached {
          color: red !important;
        }
      }
    }
    .targetInvestment {

    }
    .targetInvestmentAllocated {

    }
    .targetInvestmentAllocation {

    }
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #00aeef!important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: solid transparent 0px;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: solid transparent 0px;
  }
  .MuiDataGrid-root {
    .maxExceed {
      .MuiDataGrid-cell.projectedInvestment {
        color: $error!important;
      }
    }
  }
  .MuiDataGrid-footerContainer {
    button:hover {
      background: transparent;
      border: 0vw solid transparent;
      transition: all 200ms linear;
    }
  }
}