.actionsTopMenuInvestments {
  .formContainer {
    .columnsWrap {
      input, .MuiSelect-outlined {
        width: 100%;
      }
      @media (min-width: 600px) {
        input, .MuiSelect-outlined {
          width: 95%;
        }
        display: flex;
        .columnLeft {
          flex: 50%;
        }
        .columnRight {
          flex: 50%;
        }
      }
      .attributeContainer {
        margin-bottom: 1.5rem!important;
      }
    }
    .header {
      grid-row: 1;
      display: block;
      font-family: "CeraProMedium", -apple-system, BlinkMacSystemFont, sans-serif;
      margin: 2vh 0 .5vh;
    }
    .MuiSelect-iconOutlined {
      right: 1rem!important;
    }
  }
  .MuiDialog-paperWidthLg {
    width: 75vw;
    @include media-min($lg) {
      width: 50vw;
    }
  }
  #form-dialog-title {
    margin-top: 1rem;
  }
  .MuiDialogActions-root {
    &:first-child {
      padding-top: 0;
    }
    padding: 0 2rem 2rem 2rem!important;
    .textButton span {
      font-weight: bold;
    }
  }
  .MuiAutocomplete-endAdornment {
    button:hover {
      background: transparent;
      border: 0px!important;
      transition: all 200ms linear;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 2rem;
  }
  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 0px;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0px;
      width: 95%;
    }
  }
}