.application-dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  .dialog {
    margin: 10vh 10vw 10vh 8vw;
    padding: 0 2vw;
    width: 80vw;
    height: 80vh;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    a {
      position: sticky;
      float: right;
      top: 0;
      padding: 2vw 0 2vw 2vw;
    }

    .application-table {
      margin: 2vw 0vw;
      word-break: break-word;

      .section-header {
        margin: 5vw 0vw 1vw 0vw;
        font-size: 1.5rem;
        font-family: "CeraProMedium";
      }

      .table-row {
        display: grid;
        grid-template-columns: 1fr 2fr;
        row-gap: 1vw;
        column-gap: 2vw;
        padding: 1vw 0;

        .attribute {
          text-transform: capitalize;
          font-family: "CeraProRegular";
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }

        p {
          margin: 0;
        }

        a {
          padding: 0;
        }

        @media (min-width: 400px) {
          grid-template-columns: 1fr 2fr;
        }

        @media (min-width: 800px) {
          grid-template-columns: 1fr 3fr;
        }
      }
    }
  }
}
